import React from 'react';
import './NewFeature.css';


function NewFeature() {
  return (
    <div className="loader-container">
      <div className="loader-before"></div>
      <div className="loader-after"></div>
    </div>
  );
}

export default NewFeature;
