import React from 'react';
import AppLoginCard from './AppLoginCard';
import './LoginPage.css'; // Import the CSS file
import Logo from './Logo';
import NewFeature from '../ExtraComponents/NewFeature';

function LoginPage() {

  window.history.replaceState({}, document.title, "/");

  return (
    <div className="login-page">
      <div className='logo'>
        <Logo />
      </div>
      <div className="login-card">
        <AppLoginCard />
      </div>
    </div>
  );
}

export default LoginPage;
