import React, { useState } from 'react';
import TodoForm from './TodoForm';
import { RiCloseCircleLine } from 'react-icons/ri';
import { TiEdit } from 'react-icons/ti';
// import { deleteDoc, doc } from 'firebase/firestore';
// import { db } from './FirebaseConfig'; // Import your Firestore instance


const Todo = ({ todos, completeTodo, removeTodo, updateTodo, removeTodoFromFirebase}) => {
  const [edit, setEdit] = useState({
    id: null,
    value: ''
  });









  const submitUpdate = value => {
    console.log('Value to be updated:', value); // Log the value being passed for updating
    console.log('Todo ID:', edit.id); // Log the ID of the todo item to be updated
    updateTodo(edit.id, value);
    setEdit({
      id: null,
      value: ''
    });
  };

  if (edit.id) {
    return <TodoForm edit={edit} onSubmit={submitUpdate} />;
  }

  return todos.map((todo, index) => (
    <div
      className={todo.isComplete ? 'todo-row complete' : 'todo-row'}
      key={index}
      onDoubleClick={() => completeTodo(todo.id)}
    >
      <div key={todo.id} onClick={() => completeTodo(todo.id)}>
        {todo.text}
      </div>
      <div className='icons'>
        <RiCloseCircleLine
        onClick={() => removeTodoFromFirebase(todo.id)} // Call removeTodoFromFirebase when the icon is clicked          
        className='delete-icon'
        />
        <TiEdit
          onClick={() => setEdit({ id: todo.id, value: todo.text })}
          className='edit-icon'
        />
      </div>
    </div>
  ));
};

export default Todo;