import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { getAuth } from 'firebase/auth';
import './HomePage.css';
import TodoList from '../components/TodoList';

function HomePage() {
  const [username, setUsername] = useState(""); // State to store the username

  useEffect(() => {
    const auth = getAuth(); // Get the Auth instance

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        const fetchedUsername = user.displayName; // Assuming Firebase user has a displayName
        setUsername(fetchedUsername);
      } else {
        // No user is signed in.
        setUsername(""); // Set username as empty when no user is signed in
      }
    });

    return () => unsubscribe(); // Clean up Firebase listener on component unmount
  }, []);

  const todos = []; // Define the todos array here or fetch it from an API or local storage

  window.history.replaceState({}, document.title, "/");

  return (
    <div className='Home'>
      <div className="todo-app">
        <TodoList todos={todos} username={username} />
      </div>
    </div>
  );
}

export default HomePage;
