import React from 'react';
import AppSignUpCard from './AppSignUpCard';
import './LoginPage.css'; // Import the CSS file
import Logo from './Logo';

function SignUp() {
  return (
    <div>
        <div className="login-page">
      <div className='logo'>
        <Logo />
      </div>
      <div className="login-card">
        <AppSignUpCard />
      </div>
    </div>
    </div>
  )
}

export default SignUp
