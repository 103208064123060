import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AppLoginCard.css';
import { Tilt } from 'react-tilt';
import { createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from './FirebaseConfig';

export default function AppSignUpCard() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
  
    const handleUsernameChange = (e) => {
      setUsername(e.target.value);
    };
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };
  
    const handleGoogleSignUp = async () => {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        // Additional logic with user data or navigate to home
        navigate('/home');
      } catch (error) {
        console.error('Error signing up with Google:', error);
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      createUserWithEmailAndPassword(auth, username, password)
        .then(async (res) => {
          const user = res.user;
          await updateProfile(user, {
            displayName: username,
          });
          navigate('/home');
        })
        .catch((err) => console.log('Error-', err));
    };
  
    return (
      <div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <Tilt tiltReverse={true} tiltAngleXInitial={-30} tiltMaxAngleX={30} tiltMaxAngleY={30}>
                <div className="app-login-card custom-card">
                 <h1 className="card-title">Sign Up</h1>
                 <div className="underline-title"></div>
                 <form onSubmit={handleSubmit} className="form">
                    <div className="form-group">
                      <label htmlFor="username">Email</label>
                      <br />
                      <input
                        type="text"
                        id="username"
                        className="form-content"
                        value={username}
                        onChange={handleUsernameChange}
                        autoComplete='off'
                      />
                      <div className="form-border"></div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Set Password</label>
                      <br />
                      <input
                        type="password"
                        id="password"
                        className="form-content"
                        value={password}
                        onChange={handlePasswordChange}
                        autoComplete='off'
                      />
                      <div className="form-border"></div>
                      <button className="link-buttonFP" onClick={() => navigate('/')}>
                        {/* Forgot password? */}
                      </button>
                    </div>
                    <button type="submit" className="btn custom-btn">Sign Up</button>
                    
                 </form>
                 <button className="link-buttonGS" onClick={handleGoogleSignUp}>
                 SignUp with <img src="../img/g.png" alt="" style={{width:'15px'}}/>oogle 
                </button>
                 <button className="link-buttonDHA" onClick={() => navigate('/')}>
                    I already have an account!
                 </button>
                </div>
              </Tilt>
            </div>
          </div>
        </div>
      </div>
    );
}