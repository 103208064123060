import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import TodoForm from './TodoForm';
import Todo from './Todo';
import './TodoList.css';
import './AppLoginCard.css';
import { Tilt } from 'react-tilt';
import { doc, collection, addDoc, getDocs, updateDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from './FirebaseConfig'; // Import the Firestore instance

function TodoList({ username }) { // Pass the username as a prop
  const navigate = useNavigate();
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    if (username) {
      fetchTodosFromFirestore(username); // Fetch todos for the specified user
    }
  }, [username]);

  const fetchTodosFromFirestore = async (username) => {
    try {
      const todosCollection = collection(db, `users/${username}/todos`); // Create a collection for each user
      const snapshot = await getDocs(todosCollection);
      const fetchedTodos = snapshot.docs.map((doc) => {
        const data = doc.data();
        const { id } = doc;
        return { id, ...data };
      });
      setTodos(fetchedTodos);
    } catch (error) {
      console.error('Error fetching todos:', error);
    }
  };

  const addTodo = async (todo) => {
    try {
      if (!todo.text.trim() || !username) {
        return;
      }
      const todosCollection = collection(db, `users/${username}/todos`); // Add todo to the user-specific collection
      await addDoc(todosCollection, todo);
      fetchTodosFromFirestore(username); // Fetch updated todos after addition
    } catch (error) {
      console.error('Error adding todo:', error);
    }
  };

  const updateTodo = async (todoId, newValue) => {
    try {
      if (!newValue.text || /^\s*$/.test(newValue.text) || !username) {
        return;
      }

      setTodos((prev) =>
        prev.map((item) => (item.id === todoId ? newValue : item))
      );
      const todoDocRef = doc(db, `users/${username}/todos/${todoId}`); // Construct the document reference
      const todoSnapshot = await getDoc(todoDocRef);
      if (todoSnapshot.exists()) {
        const updatedTodos = { ...todoSnapshot.data(), ...newValue };
        await updateDoc(todoDocRef, updatedTodos);
        console.log('Todo updated in Firebase');
        fetchTodosFromFirestore(username);
      }
    } catch (error) {
      console.error('Error updating todo:', error);
    }
  };

  const removeTodo = async (id) => {
    try {
      if (!username) {
        return;
      }
      setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== id));
    } catch (error) {
      console.error('Error removing todo:', error);
    }
  };

  const removeTodoFromFirebase = async (id) => {
    try {
      const todoDocRef = doc(db, `users/${username}/todos/${id}`);
      await deleteDoc(todoDocRef);
      console.log('Deleted from Firebase');
      removeTodo(id);
    } catch (error) {
      console.error('Error removing todo:', error);
    }
  };

  const completeTodo = async (id) => {
    try {
      if (!username) {
        return;
      }
      const todoDoc = collection(db, `users/${username}/todos`).doc(id);
      const todo = await todoDoc.get();
      if (todo.exists()) {
        const updatedTodo = { ...todo.data(), isComplete: !todo.data().isComplete };
        await updateDoc(todoDoc, updatedTodo);
        fetchTodosFromFirestore(username);
      }
    } catch (error) {
      console.error('Error completing todo:', error);
    }
  };

  const navigateToHomePage2 = () => {
    navigate('/homepage2', { state: { todos } });
  };

  return (
    <div className='todo-card'>
      <h1>Hello, {username} what's Your Plan?</h1>
      <TodoForm onSubmit={addTodo} />
      <Todo
        todos={todos}
        completeTodo={completeTodo}
        removeTodo={removeTodo}
        updateTodo={updateTodo}
        removeTodoFromFirebase={removeTodoFromFirebase}
      />
      <div className="go-to-homepage2-button">
        <Tilt tiltReverse={true} tiltAngleXInitial={-30} tiltMaxAngleX={30} tiltMaxAngleY={30}>
          <button onClick={navigateToHomePage2}>
            Let's Go <FontAwesomeIcon icon={faFire} />
          </button>
        </Tilt>
      </div>
    </div>
  );
}

export default TodoList;
