import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AppLoginCard.css';
import { Tilt } from 'react-tilt';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from './FirebaseConfig';

function AppLoginCard() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // Handle successful Google sign-in, navigate to home, etc.
      navigate('/home');
    } catch (error) {
      // Handle Google sign-in error
      console.error('Error signing in with Google:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
     signInWithEmailAndPassword(auth, username, password)
       .then((res) => {
         navigate('/home');
       })
       .catch((err) => {
         // Handle other errors
         console.log('Error:', err.code, err.message);
         let errorMessage = '';
         switch (err.code) {
           case 'auth/invalid-email':
             errorMessage = 'No user found with that email address.';
             break;
           case 'auth/invalid-credential':
             errorMessage = 'Incorrect password.';
             break;
           case 'auth/too-many-requests':
            errorMessage = 'Too Many Requests,Try After Sometime';
            break;
           case 'auth/network-request-failed':
            errorMessage = 'Network Error,Check Your Internet Connection';
            break;
           default:
             errorMessage = 'Enter Valid Information.';
             break;
         }
         setErrorMessage(errorMessage);
       });
   };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <Tilt tiltReverse={true} tiltAngleXInitial={-30} tiltMaxAngleX={30} tiltMaxAngleY={30}>
            <div className="app-login-card custom-card">
              <h1 className="card-title">Login</h1>
              <div className="underline-title"></div>
              <form onSubmit={handleSubmit} className="form">
                <div className="form-group">
                  <label htmlFor="username">Email</label>
                  <br />
                  <input
                    type="text"
                    id="username"
                    className="form-content"
                    value={username}
                    onChange={handleUsernameChange}
                  />
                  <div className="form-border"></div>
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <br />
                  <input
                    type="password"
                    id="password"
                    className="form-content"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <div className="form-border"></div>
                  <button className="link-buttonFP" onClick={() => navigate('/')}>
                    {/* Forgot password? */}
                  </button>
                </div>
                <div className="error-message">{errorMessage}</div>
                <button type="submit" className="btn custom-btn">LOGIN</button>
                
              </form>
              <button className="link-buttonGL" onClick={handleGoogleSignIn}>
                 Login with <img src="../img/g.png" alt=""  style={{width:'15px'}}/>oogle 
                </button>
              <button className="link-buttonDHA" onClick={() => navigate('/signup')}>
                Don't have an account yet?
              </button>
            </div>
          </Tilt>
        </div>
      </div>
    </div>
  );
}

export default AppLoginCard;
