import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDy3bfbW8BjU2z0PitJD6utSKxGRdqi5cI",
  authDomain: "hard-ffeab.firebaseapp.com",
  projectId: "hard-ffeab",
  storageBucket: "hard-ffeab.appspot.com",
  messagingSenderId: "298220122410",
  appId: "1:298220122410:web:7e1b588a719114a7462e53"
};


const app = initializeApp(firebaseConfig);

const auth=getAuth();

const db = getFirestore(app);
export{ app, auth , db};