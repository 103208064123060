import React from 'react';
import { Tilt } from 'react-tilt';
import './Logo.css';
import NewFeature from '../ExtraComponents/NewFeature';


export default function Logo() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-6 col-md-8 text-center">
          <Tilt tiltReverse={true} tiltAngleXInitial={-30} tiltMaxAngleX={30} tiltMaxAngleY={30}>
            <div className='logo'>
              <h1 style={{ color: '#fff',  textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)' }}>75 HARD</h1>
              <NewFeature />
            </div>
            <p className="mt-2">Commit | Endure | Conquer</p>
          </Tilt>
        </div>
      </div>
    </div>
  );
}
