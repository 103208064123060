import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import HomePage2 from './HomePage2';
import SignUp from './SignUp';

function App() {

  // Clear local history
  window.history.replaceState({}, document.title, "/");

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* <Route path="/75-hard-react/" element={<Navigate to="/login" />} /> */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/homepage2" element={<HomePage2 />} />
          <Route path="/signup" element={<SignUp />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;